export const API_URLS = {
  AGDATA: {
    ASSIGNMENT: {
      ASSIGN_PRODUCTS: '/api/v1/company/:companyId/agdata/emission-factors/assignment',
      SUPPLIERS: '/api/v1/company/:companyId/agdata/emission-factors/assignment/suppliers',
    },
    CREATE: '/api/company/:companyId/agdata/emission-factors',
    DATA: '/api/company/:companyId/agdata/emission-factors/:id',
    FILES: {
      ALL: '/api/v1/company/:companyId/agdata/emission-factors/:emissionFactorId/files',
      DELETE: '/api/v1/company/:companyId/agdata/emission-factors/:emissionFactorId/files/:id',
      DOWNLOAD: '/api/v1/company/:companyId/agdata/emission-factors/:emissionFactorId/files/:id',
    },
    PRODUCES: '/api/company/:companyId/agdata/produces',
    PUBLISH: '/api/v1/company/:companyId/agdata/emission-factors/:id/publish',
    SELECT_INGREDIENTS:
      '/api/company/:companyId/agdata/emission-factors/assignment/simple-ingredients',
    SELECT_PRODUCTS: '/api/company/:companyId/agdata/emission-factors/assignment/products',
    SELECT_SUPPLIERS: 'api/v1/company/:companyId/agdata/suppliers',
    VALIDATE: '/api/v1/company/:companyId/agdata/emission-factors/:id/validate',
  },
  AI_CHAT: {
    CREATE: '/api/company/:companyId/chat',
    MESSAGE_STREAM: '/api/company/:companyId/chat/:id/message-stream',
  },
  Analysis: {
    BREADCRUMB: '/api/company/:companyId/analysis/dimensions/:dimension/:id/root-path',
    CATEGORY_PRODUCT_HIERARCHY:
      '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/hierarchy',
    COMPANY_SYNC: '/api/company/:companyId/sync/status',
    Dashboard: {
      COUNT: '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/totals',
      EMISSION_TIME_SERIES:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/emission-time-series',
      EMISSION_VARIATION:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/variations-by-product',
      EMISSION_VARIATION_TABLE:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/emission-totals-by-product',
      IMPACT_STAGES:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/impact-stages',
      PRODUCT_PRIMARY_DETAILS: '/api/company/:companyId/analysis/product/:productId',
      STATS: '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/stats',
      SUB_DIMENSION_IMPACTS:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/sub-dimension',
    },
    EF_LIST: '/api/company/:companyId/analysis/product/:productId/custom-emission-factors',
    FAVOURITE: '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/favourite',
    FAVOURITES: '/api/company/:companyId/favourites/paged',
    INGREDIENTS: {
      EMISSION_VARIATION:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/variations-by-ingredient',
      EMISSION_VARIATION_TABLE:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/emission-totals-by-ingredient',
      INGREDIENTS_LIST: '/api/company/:companyId/analysis/product/:productId/ingredients',
      INGREDIENTS_TABLE:
        '/api/company/:companyId/analysis/dimensions/product/:productId/contribution-tree',
      STATS_BY_INGREDIENT:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/stats-by-ingredient',
      STATS_BY_INGREDIENT_DETAILED:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/stats',
      SUPPLY_CHAIN:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/ingredient-chain',
    },
    SUPPLIER_FILTERS: '/api/company/:companyId/analysis/dimensions/supplier',
    SUPPLIERS: {
      EMISSION_VARIATION:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/variations-by-supplier',
      EMISSION_VARIATION_PRODUCTS_TABLE:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/emission-totals-by-supplier/:supplierId/supplied-products',
      EMISSION_VARIATION_TABLE:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/emission-totals-by-supplier',
      SUPPLIER_IMPACT_STATS:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/emission-totals-by-supplier/:supplierId',
      SUPPLIER_IMPACT_TREEMAP:
        '/api/company/:companyId/analysis/dimensions/:dimension/:dimensionId/supplier-impacts',
    },
  },
  COUNTRIES: `/api/v1/location/countries`,
  ENGAGEMENTS: {
    CANCEL_INVITE: '/api/company/:companyId/supplier-invitations/invitations/:inviteId/cancel',
    INVITE_DETAILS: '/api/company/:companyId/supplier-invitations/invitations/:inviteId',
    L1_SUPPLIER_LIST: '/api/company/:companyId/supplier-invitations/suppliers',
    SUPPLIER_DETAILS: '/api/company/:companyId/supplier-invitations/suppliers/:supplierId',
    SUPPLIER_INVITES: '/api/company/:companyId/supplier-invitations/invitations',
  },
  GRAPHQL: '/api/graphql',
  GRAPHQL_EXPORT_CSV: '/api/graphql/export/csv',
  MOCK_TABLE: '/api/table',
  SCENARIOS: {
    AVAILABLE_INGREDIENTS: {
      INGREDIENTS:
        '/api/company/:companyId/scenarios/:scenarioId/ingredients/:nodeId/composition/available',
      PRODUCTS:
        '/api/company/:companyId/scenarios/:scenarioId/products/:nodeId/composition/available',
    },
    CREATE: '/api/company/:companyId/scenarios',
    EDIT: '/api/company/:companyId/scenarios/:scenarioId',
    FAVOURITE: '/api/company/:companyId/scenarios/:scenarioId/favourite',
    INGREDIENT_CHAIN:
      '/api/company/:companyId/scenarios/:scenarioId/products/:productId/ingredient-chain',
    INGREDIENT_COMPOSITION:
      '/api/company/:companyId/scenarios/:scenarioId/ingredients/:nodeId/composition',
    INGREDIENT_COMPOSITION_IMPACT:
      '/api/company/:companyId/scenarios/:scenarioId/ingredients/:nodeId/composition/impact',
    INGREDIENT_COMPOSITION_LOOKUP:
      '/api/company/:companyId/scenarios/:scenarioId/ingredients/:nodeId/composition/available/lookup',
    INGREDIENT_DEFAULT_COMPOSITION: {
      INGREDIENTS:
        '/api/company/:companyId/scenarios/:scenarioId/ingredients/:nodeId/composition/default',
      PRODUCTS:
        '/api/company/:companyId/scenarios/:scenarioId/products/:nodeId/composition/default',
    },
    PRODUCT_COMPOSITION:
      '/api/company/:companyId/scenarios/:scenarioId/products/:nodeId/composition',
    PRODUCT_COMPOSITION_IMPACT:
      '/api/company/:companyId/scenarios/:scenarioId/products/:nodeId/composition/impact',
    PRODUCT_COMPOSITION_LOOKUP:
      '/api/company/:companyId/scenarios/:scenarioId/products/:nodeId/composition/available/lookup',
    PRODUCT_SCENARIO: '/api/company/:companyId/scenarios/:scenarioId/products/:productId',
    SCENARIO_DETAILS: '/api/company/:companyId/scenarios/:scenarioId/product',
    SCENARIO_UNARCHIVE: '/api/company/:companyId/scenarios/:scenarioId/active',
    SCENARIOS_LOOKUP: '/api/company/:companyId/scenarios/lookup',
    SHARE: '/api/company/:companyId/scenarios/:scenarioId/share',
    SHORT_CODE_VERIFY: '/api/scenarios/:shortCode',
  },
  SEARCH: '/api/company/:companyId/search/:searchType',
  SETTINGS: '/api/company/:companyId/settings',
  SOURCE_COMPANIES: '/api/company/:companyId/source-companies',
  ZOHO_ASAP_USER_TOKEN: `${process.env.REACT_APP_IDENTITY_API}/api/v1/desk/token/asap-widget`,
}
