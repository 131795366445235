import React, { useEffect, useState } from 'react'
import { useCompanies } from 'api/useCompanies'
import { useVerifyScenarioShortCode } from 'api/useVerifyScenarioShortCode'
import { COMPANY_ID_SEARCH_PARAM } from 'constants/'
import { ROUTE_URLS } from 'constants/routeUrls'
import { useNavigate, useParams } from 'react-router-dom'
import { resolveUrl } from 'utils/resolveUrl'
import { GET_SUPPORT } from 'constants/helpContent'
import { LottieLoader } from '@mondra/ui-components'

export default function ScenarioRedirectByShortCode() {
  const [isError, setIsError] = useState(false)

  const { companies } = useCompanies()
  const { scenarioShortCode } = useParams()
  const navigate = useNavigate()

  const { data, error } = useVerifyScenarioShortCode({ scenarioShortCode })

  useEffect(() => {
    if (error) {
      setIsError(true)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      let companyId: string | null = null

      const company = companies.find(c => c.id === data.companyId)
      if (company) {
        companyId = data.companyId
      } else {
        const sharedCompany = companies.find(c => c.id === data.sharedToCompanyId)
        if (sharedCompany) {
          companyId = data.sharedToCompanyId
        }
      }

      if (companyId) {
        const path = resolveUrl(
          ROUTE_URLS.SCENARIO_DASHBOARD,
          { scenarioId: data.scenarioId },
          {
            [COMPANY_ID_SEARCH_PARAM]: companyId,
          }
        )
        navigate(path)
      } else {
        setIsError(true)
      }
    }
  }, [companies, data, navigate])

  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-center">
      {isError ? (
        <div className="flex flex-col items-center gap-y-2">
          <svg
            width="1384"
            height="120"
            viewBox="0 0 1384 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_8986_57897)">
              <rect x="412.5" y="-219.5" width="559" height="559" rx="279.5" stroke="#BBF7D0" />
              <rect x="540.5" y="-91.5" width="303" height="303" rx="151.5" stroke="#BBF7D0" />
              <rect x="604.5" y="-27.5" width="175" height="175" rx="87.5" stroke="#BBF7D0" />
              <rect x="636.5" y="4.5" width="111" height="111" rx="55.5" stroke="#BBF7D0" />
              <rect x="652" y="20" width="80" height="80" rx="40" fill="#D9F2F0" />
              <path
                d="M667.5 60C667.5 64.8456 668.937 69.5825 671.629 73.6115C674.321 77.6405 678.147 80.7807 682.624 82.635C687.101 84.4894 692.027 84.9746 696.78 84.0292C701.532 83.0839 705.898 80.7505 709.324 77.3241C712.751 73.8977 715.084 69.5322 716.029 64.7797C716.975 60.0272 716.489 55.101 714.635 50.6243C712.781 46.1475 709.64 42.3211 705.611 39.629C701.582 36.9369 696.846 35.5 692 35.5C685.502 35.5 679.271 38.0812 674.676 42.6759C670.081 47.2705 667.5 53.5022 667.5 60ZM708.013 73.5625L678.438 43.9875C682.464 40.6362 687.597 38.9107 692.83 39.1496C698.063 39.3884 703.017 41.5744 706.722 45.2785C710.426 48.9826 712.612 53.9373 712.85 59.1702C713.089 64.4031 711.364 69.5364 708.013 73.5625ZM678.42 76.03C674.182 72.4311 671.544 67.2985 671.085 61.7577C670.626 56.2169 672.382 50.72 675.97 46.4725L705.528 76.03C701.737 79.2325 696.936 80.9896 691.974 80.9896C687.012 80.9896 682.21 79.2325 678.42 76.03Z"
                fill="#3AACA3"
              />
            </g>
            <defs>
              <clipPath id="clip0_8986_57897">
                <rect width="1384" height="120" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="w-80 text-center">
            <div className="text-base font-semibold text-coolGray-900">No Selected suppliers</div>
            <div className="text-sm text-coolGray-500">
              We could not find the scenario you are trying to access. Please ask the sender of this
              URL to check the scenario is still available.
            </div>
            <div className="mt-4 text-sm text-coolGray-500">
              <a
                href={GET_SUPPORT}
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold text-primary-500"
              >
                Contact Mondra support
              </a>{' '}
              for additonal help.
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-y-4">
          <LottieLoader
            lottieType="butterflyLottie"
            style={{ height: '10.25rem', width: '10.25rem' }}
          />
          <div className="text-base font-semibold text-coolGray-900">
            Validating scenario URL...
          </div>
        </div>
      )}
    </div>
  )
}
